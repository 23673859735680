import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="w-full text-center text-xs absolute bottom-0 pt-0.5 pb-2">
      &copy; Copyright 2020-{currentYear} Yuka Masuda
    </footer>
  );
};

export default Footer;
