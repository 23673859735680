import React from "react";
import "./base.css";
import Container from "./container";
import Navigation from "./navigation";
import Footer from "./footer";
import "../styles/global.css";

class Template extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="relative min-h-screen">
        <Container>
          <Navigation />
          {children}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Template;
