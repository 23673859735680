import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview";

const BlogIndex = ({ data, location }) => {
  const posts = data?.allContentfulBlogPost?.edges;
  return (
    <Layout location={location}>
      <div style={{ background: "#fff" }}>
        <div className="wrapper">
          <ul>
            {posts?.map(({ node }) => (
              <li key={node.slug}>
                <ArticlePreview article={node} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
