import React from "react";
import { Link } from "gatsby";
export default ({ article }) => (
  <div className="mb-6">
    <div>
      <h3 className="text-2xl mb-2">
        <Link to={`/posts/${article.slug}`}>{article.title}</Link>
      </h3>
      <time className="text-xs text-gray-700 block mb-3">
        {article.publishDate}
      </time>
      <p
        dangerouslySetInnerHTML={{
          __html: article.description?.childMarkdownRemark?.html,
        }}
      />
    </div>
  </div>
);
